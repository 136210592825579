@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  font-family: "Roboto", sans-serif;
}
.rectangle {
  height: 8px;
  border-radius: 2px;
  width: 100%;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42e198', endColorstr='#0D98BA',GradientType=1 );
  background-image: linear-gradient(272deg, #0D98BA, #42e198);
}

.shadow-input{
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08)
}

input:focus{
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}



.inputLoader {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid #96D9C1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 